import React from 'react';
import Section from "../components/Section";
import Footer from "../components/Footer";
import Entete from '../components/Entete';
// import Burger from '../components/burger/Burger';

const Services = () => {
    return (
        <> 
        <Entete />
        {/* <Burger /> */}
        <Section />
        <Footer />
      </>
    );
}

export default Services;
